export default function LogoutIcon({ className }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`size-4 ${className}`}
    >
      <g clipPath="url(#clip0_2103_52561)">
        <path
          d="M6.86671 5.13333C6.60671 5.39333 6.60671 5.80667 6.86671 6.06667L8.13337 7.33333H2.00004C1.63337 7.33333 1.33337 7.63333 1.33337 8C1.33337 8.36667 1.63337 8.66667 2.00004 8.66667H8.13337L6.86671 9.93333C6.60671 10.1933 6.60671 10.6067 6.86671 10.8667C7.12671 11.1267 7.54004 11.1267 7.80004 10.8667L10.1934 8.47333C10.4534 8.21333 10.4534 7.79333 10.1934 7.53333L7.80004 5.13333C7.54004 4.87333 7.12671 4.87333 6.86671 5.13333ZM13.3334 12.6667H8.66671C8.30004 12.6667 8.00004 12.9667 8.00004 13.3333C8.00004 13.7 8.30004 14 8.66671 14H13.3334C14.0667 14 14.6667 13.4 14.6667 12.6667V3.33333C14.6667 2.6 14.0667 2 13.3334 2H8.66671C8.30004 2 8.00004 2.3 8.00004 2.66667C8.00004 3.03333 8.30004 3.33333 8.66671 3.33333H13.3334V12.6667Z"
          fill="#808080"
        />
      </g>
      <defs>
        <clipPath id="clip0_2103_52561">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
