import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import Link from 'next/link'
import { PromoBannerProps } from 'shared/types/databaseTypes'

export default function PromotionalBanner({
  className,
  bannerString,
  textColor,
  linkURL,
}: PromoBannerProps & {
  className?: string
}) {
  const stringArr: (string | ReactJSXElement)[] = bannerString.split('\\n')
  const firstString = stringArr.shift()
  const elementChildren = [firstString]
  for (let substring of stringArr) {
    elementChildren.push(<br className="md:hidden" />)
    elementChildren.push(substring)
  }
  return (
    <Link
      href={linkURL}
      className={`${className ?? ''} flex cursor-pointer flex-row items-center justify-center bg-black shadow-md`}
    >
      <span
        className="px-4 py-3 text-center text-[14px] md:py-4"
        style={{ color: textColor }}
      >
        {...elementChildren}
      </span>
    </Link>
  )
}
