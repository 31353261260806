export default function Arrow(
  {
    className,
    isDown,
  }: {
    className?: string
    isDown?: boolean
  } = {
    className: '',
    isDown: false,
  },
) {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`w-[12px] ${className}`}
    >
      <circle cx={6} cy={6} fill="black" r={6}></circle>
      <path
        d={isDown ? 'M3.7,5.5 L6,7.8 L8.3,5.5' : 'M3.7,7 L6,4.7 L8.3,7'}
        strokeLinejoin="round"
        strokeLinecap="round"
        stroke="white"
        strokeWidth={1}
      ></path>
    </svg>
  )
}
