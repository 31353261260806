'use client'

import Image from 'next/image'
import UserIconBG from './userIconBG.png'
import UpArrow from './BlackCircleArrow'
import { RefObject, useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import PersonIcon from '../icons/Person'
import GiftBoxIcon from '../icons/GiftBox'
import LogoutIcon from '../icons/Logout'
import { useOutsideClickHandler } from 'lib/client/reactUtils'

interface UserIconMenuProps {
  name: { firstname: string; lastname: string }
}

export default function UserIconMenu({ name }: UserIconMenuProps) {
  const [isOpen, setIsOpen] = useState(false)

  const menuRef = useRef(null)
  useOutsideClickHandler(menuRef, () => setIsOpen(() => false))

  const initials =
    `${name.firstname.charAt(0)}${name.lastname.charAt(0)}`.toUpperCase()

  return (
    <div className="relative" ref={menuRef}>
      <div
        className="flex h-[32px] w-[32px] cursor-pointer flex-row items-center justify-center text-center"
        onClick={() => setIsOpen((current) => !current)}
      >
        <Image
          src={UserIconBG}
          width={32}
          height={32}
          alt={'User icon'}
          className="absolute left-0 top-0 rounded-full"
        />
        <span className="z-10 font-normal text-white">{initials}</span>
        <UpArrow
          className="absolute bottom-[-2px] right-[-2px]"
          isDown={!isOpen}
        />
      </div>

      <ul
        className={`absolute bottom-[-140px] right-0 z-50 flex w-auto flex-col rounded-lg border border-neutral-100 bg-white py-2 shadow-lg transition-opacity duration-150 ${isOpen ? 'opacity-100' : 'pointer-events-none opacity-0'}`}
      >
        <li>
          <Link
            href={'/account'}
            className="flex flex-row items-center gap-2 px-3 py-2 hover:bg-neutral-100 active:bg-neutral-300"
          >
            <PersonIcon />
            <span className="whitespace-nowrap text-[14px]">Profile</span>
          </Link>
        </li>
        <li>
          <Link
            href={'/gifts'}
            className="flex flex-row items-center gap-2 px-3 py-2 hover:bg-neutral-100 active:bg-neutral-300"
          >
            <GiftBoxIcon />
            <span className="whitespace-nowrap text-[14px]">My gifts</span>
          </Link>
        </li>
        <li>
          <Link
            href={'/logout'}
            className="flex flex-row items-center gap-2 px-3 py-2 hover:bg-neutral-100 active:bg-neutral-300"
          >
            <LogoutIcon />
            <span className="whitespace-nowrap text-[14px]">Log out</span>
          </Link>
        </li>
      </ul>
    </div>
  )
}
