import { twMerge } from 'tailwind-merge'

interface ContainerProps {
  children: React.ReactNode | React.ReactNode[]
  className?: string
  outerClassName?: string
  noContainer?: boolean
  id?: string
}

export default function Container({
  children,
  className = '',
  id,
  outerClassName = '',
  noContainer = false,
}: ContainerProps) {
  return (
    <div
      id={id}
      className={twMerge(
        `px-[20px] md:px-[36px] lg:px-[100px] xl:px-[200px] ${outerClassName}`,
      )}
    >
      <div
        className={twMerge(
          `${noContainer ? '' : 'container max-w-[1200px]'} ${className}`,
        )}
      >
        {children}
      </div>
    </div>
  )
}
