import { RefObject, useEffect } from 'react'

/**
 * Hook that calls func if user clicks outside of the passed ref
 */
export function useOutsideClickHandler(
  ref: RefObject<HTMLElement>,
  func: () => void,
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        func()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
